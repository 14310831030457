<template>
  <b-row>
    <b-col cols="12">
      <b-card title="List of Divisions">
        <loading
          :active="isLoading"
          loader="bars"
          color="#7367f0"
          :is-full-page="fullPage"
        />
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          style-class="vgt-table striped"
          :sort-options="{
            enabled: false,
          }"
          :total-rows="totalRecords"
          :is-loading.sync="isLoadingTable"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            skipDiacritics: true,
          }"
          :pagination-options="{
            enabled: true,
            perPageDropdownEnabled: false,
            dropdownAllowAll: false,
          }"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'action'">
              <b-button
                v-b-tooltip.hover.bottom="'Edit'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="btn-icon"
                style="margin-right: 2px; margin-bottom: 2px;"
                :to="{ name: 'edit-servolution-ministry-setup', params: { eventId: $route.params.id, divisionId: props.row.id } }"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BCard, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import _ from 'lodash'
// import Cookies from 'js-cookie'
import * as moment from 'moment'
import CryptoJS from 'crypto-js'
import Loading from 'vue-loading-overlay'
import Ripple from 'vue-ripple-directive'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    VueGoodTable,
    BRow,
    BCol,
    BButton,
    // BDropdown,
    // BDropdownItem,
    Loading,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      moment,
      isLoading: true,
      isLoadingTable: false,
      fullPage: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Division',
          field: 'name',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
          searchTerm: '',
        },
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 0,
        perPage: 10,
      },
      searchTerm: '',
      isRendered: false,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign(this.serverParams, Object.assign(newProps))
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPerPage * (params.currentPage - 1) })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    onSearch: _.debounce(function (params) {
      this.isLoading = true
      this.updateParams({ columnFilters: params })
      this.loadItems()
      return false
    }, 350),
    loadItems() {
      // Set data
      const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
      const divisions = userData[0].divisions.map(elem => elem).join(',')

      // Get the data
      this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/ministry/setup/${this.$route.params.id}/${divisions}?page=${this.serverParams.page}&perpage=${this.serverParams.perPage}&search=${this.serverParams.columnFilters.searchTerm}`)
        .then(response => {
          this.isLoading = false
          this.totalRecords = response.data.totalRecords
          this.rows = response.data.rows
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  border: none;
  font-size: 1rem;
}
.vgt-table thead th {
  // border-bottom: 2px solid #ebe9f1;
  outline: none;
  background-color: #f3f2f7;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.vgt-wrap__footer {
  border: none;
}
</style>