var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"List of Divisions"}},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"bars","color":"#7367f0","is-full-page":_vm.fullPage}}),_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"style-class":"vgt-table striped","sort-options":{
          enabled: false,
        },"total-rows":_vm.totalRecords,"is-loading":_vm.isLoadingTable,"search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm,
          skipDiacritics: true,
        },"pagination-options":{
          enabled: true,
          perPageDropdownEnabled: false,
          dropdownAllowAll: false,
        }},on:{"update:isLoading":function($event){_vm.isLoadingTable=$event},"update:is-loading":function($event){_vm.isLoadingTable=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"bottom":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",staticStyle:{"margin-right":"2px","margin-bottom":"2px"},attrs:{"variant":"warning","to":{ name: 'edit-servolution-ministry-setup', params: { eventId: _vm.$route.params.id, divisionId: props.row.id } }}},[_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }